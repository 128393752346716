import React, { useState, useEffect } from "react";
import axios from "./../../config/axiosConfig";

function AddTest() {
  const [name, setName] = useState("");
  const [poster, setPoster] = useState("");
  const [subject, setSubject] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // Fetch questions to populate the list
    fetchQuestions(currentPage, searchText);
  }, [currentPage, searchText]);

  const fetchQuestions = async (page, query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}topicQuestions?page=${page}&statement=${query}`
      );
      setAllQuestions(response.data.topicQuestions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleQuestionSelect = (event) => {
    const selectedQuestionId = event.target.value;
    const selectedQuestion = allQuestions.find(
      (q) => q._id === selectedQuestionId
    );

    if (selectedQuestion && !selectedQuestions.includes(selectedQuestion)) {
      setSelectedQuestions([...selectedQuestions, selectedQuestion]);
    }
  };

  const handleQuestionRemove = (questionId) => {
    const updatedSelectedQuestions = selectedQuestions.filter(
      (question) => question._id !== questionId
    );
    setSelectedQuestions(updatedSelectedQuestions);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Store only question IDs from selectedQuestions
    const selectedQuestionIds = selectedQuestions.map(
      (question) => question._id
    );

    const newTestData = {
      name,
      poster,
      subject,
      seodesc: metaDesc,
      meta_keywords: metaKeywords,
      questions: selectedQuestionIds, // Use the array of question IDs
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}topicTests`,
        newTestData
      );

      // Display a success alert
      alert("Test created successfully!");

      // Clear input fields and selected questions
      setName("");
      setPoster("");
      setSubject("");
      setMetaDesc("");
      setMetaKeywords("");
      setSelectedQuestions([]);

      console.log("Test created:", response.data);
      // Redirect or perform other actions after successful creation
    } catch (error) {
      console.error("Error creating test:", error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchQuestions(1, searchText);
  };

  return (
    <div className="container mt-4">
      <h2>Add Test</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Subject
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="poster" className="form-label">
            Poster
          </label>
          <input
            type="text"
            className="form-control"
            id="poster"
            value={poster}
            onChange={(e) => setPoster(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="metaDesc" className="form-label">
            Meta Description
          </label>
          <input
            type="text"
            className="form-control"
            id="metaDesc"
            value={metaDesc}
            onChange={(e) => setMetaDesc(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="metaKeywords" className="form-label">
            Meta Keywords
          </label>
          <input
            type="text"
            className="form-control"
            id="metaKeywords"
            value={metaKeywords}
            onChange={(e) => setMetaKeywords(e.target.value)}
            required
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <h4>Available Questions</h4>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Questions"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <select
              multiple
              className="form-control"
              id="questions"
              onChange={handleQuestionSelect}
              size="10"
            >
              {allQuestions
                .filter(
                  (question) =>
                    !selectedQuestions.some(
                      (selected) => selected._id === question._id
                    )
                )
                .map((question) => (
                  <option key={question._id} value={question._id}>
                    {question.statement}
                  </option>
                ))}
            </select>

            {/* Pagination */}
            <nav aria-label="Questions Pagination">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-md-6">
            <h4>Selected Questions</h4>
            <ol className="list-group list-group-numbered">
              {selectedQuestions.map((question) => (
                <li
                  key={question._id}
                  className="list-group-item d-flex justify-content-between align-items-start"
                >
                  {question.statement}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleQuestionRemove(question._id)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ol>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Create Test
        </button>
      </form>
    </div>
  );
}

export default AddTest;
