const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "formula"],
    ["clean"],
    [{ align: [] }],
    [
      { color: ["#333", "red", "blue"] },
      { background: ["yellow", "green", "pink"] },
    ],
    ["blockquote", "code-block"],
  ],
};

export default modules;
