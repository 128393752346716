const subjectOptions = [
  "history",
  "polity",
  "economy",
  "geography",
  "ancient-history",
  "miscellaneous",
];

export default subjectOptions;
