import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "./../../config/axiosConfig";
import { Modal } from "react-bootstrap";

function BlogDetail() {
  const { blogId } = useParams();
  const [blog, setQuestion] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}blogs/${blogId}`
        );
        setQuestion(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    }

    fetchQuestion();
  }, [blogId]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}blogs/${blogId}`);
      // Redirect or perform other actions after deletion
      navigate("/blogs");
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <div className="container mt-4">
      {blog ? (
        <>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-secondary me-4"
              >
                Back
              </button>
              <h2 className="me-3">{blog.title} </h2>
              <h5>
                <span class="badge text-bg-primary">{blog.subject}</span>
              </h5>
            </div>
            <div>
              <Link
                to={`/edit-blog/${blogId}`}
                className="btn btn-primary ms-2"
              >
                Edit
              </Link>
              <button
                className="btn btn-danger ms-2"
                onClick={handleDeleteClick}
              >
                Delete
              </button>
            </div>
          </div>

          <div>
            <img src={blog.poster} width="100%" />
            <hr />
            <div dangerouslySetInnerHTML={{ __html: blog.desc }} />
            <hr />
            {blog.questions && blog.questions.length > 0 ? (
              <div className="row mt-3 mb-3">
                <h5>Questions</h5>
                <ol class="list-group list-group-numbered">
                  {blog.questions.map((question) => (
                    <>
                      <a
                        href={"/questions/" + question._id}
                        class="list-group-item"
                      >
                        {question.statement.slice(0, 100)}
                      </a>
                    </>
                  ))}
                </ol>
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-6">
                <h4>Meta Description</h4>
                <p>{blog.meta_desc}</p>
                <h4>Meta Keywords</h4>
                <p>{blog.meta_keywords}</p>
              </div>
              <div className="col-6">
                <h4>Created At</h4>
                <p>{new Date(blog.createdAt).toLocaleString()}</p>
                <h4>Updated At</h4>
                <p>{new Date(blog.updatedAt).toLocaleString()}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this blog?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleConfirmDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BlogDetail;
