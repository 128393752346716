import React, { useState, useEffect } from "react";
import axios from "./../../config/axiosConfig";
import { Link } from "react-router-dom";

function TestList() {
  const [tests, setTests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [subjectFilter, setSubjectFilter] = useState("");
  const [dateCreatedFilter, setDateCreatedFilter] = useState("");

  const fetchTests = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}tests?page=${page}&name=${nameFilter}&subject=${subjectFilter}&date_created=${dateCreatedFilter}`
      );
      const { tests, totalPages } = response.data;
      setTests(tests);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching tests:", error);
    }
  };

  useEffect(() => {
    fetchTests(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when searching
    fetchTests(1); // Fetch tests with updated searchText
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearFilters = () => {
    setNameFilter("");
    setDateCreatedFilter("");
    setCurrentPage(1);
    setSubjectFilter("");
    fetchTests(1); // Fetch tests with cleared filters
  };

  return (
    <>
      <div className="container mt-4">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <div>
            <h2>Tests</h2>
            <p>Around {totalPages * 10} Tests found</p>
          </div>
          <div>
            <div className="input-group">
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={handleToggleFilters}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
              </button>

              <Link to={`/add-test`} className="btn btn-success ms-2">
                Add Test
              </Link>
            </div>
          </div>
        </div>

        <div class="collapse mb-4" id="collapseExample">
          <div class="card card-body">
            <div className="row mt-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name Filter"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject Filter"
                  value={subjectFilter}
                  onChange={(e) => setSubjectFilter(e.target.value)}
                />
              </div>
              <div className="col">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date Created Filter"
                  value={dateCreatedFilter}
                  onChange={(e) => setDateCreatedFilter(e.target.value)}
                />
              </div>
              <div className="col">
                <button className="btn btn-primary me-3" onClick={handleSearch}>
                  Search
                </button>
                <button className="btn btn-danger" onClick={handleClearFilters}>
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Date Added</th>
              <th>Name</th>
              <th>Subject</th>
              <th>Questions</th>
            </tr>
          </thead>
          <tbody>
            {tests.map((test) => (
              <tr key={test._id}>
                <td>{test.createdAt}</td>
                <td>
                  <a href={`/tests/${test._id}`}>{test.name.slice(0, 80)}</a>
                </td>
                <td>{test.subject}</td>
                <td>{test.questions.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav aria-label="...">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => {
              if (index < 3 || index >= totalPages - 3) {
                return (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                );
              } else if (index === 3) {
                return (
                  <li key={index} className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }
              return null; // Skip rendering for middle page numbers
            })}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default TestList;
