import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "./../../config/axiosConfig";
import { Modal } from "react-bootstrap";

function QuestionDetail() {
  const { questionId } = useParams();
  const [question, setQuestion] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}questions/${questionId}`
        );
        setQuestion(response.data);
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    }

    fetchQuestion();
  }, [questionId]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}questions/${questionId}`
      );
      // Redirect or perform other actions after deletion
      navigate("/questions");
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-secondary me-4"
          >
            Back
          </button>
          <h2>Question Detail</h2>
        </div>
        <div>
          <Link
            to={`/edit-question/${questionId}`}
            className="btn btn-primary ms-2"
          >
            Edit
          </Link>
          <button className="btn btn-danger ms-2" onClick={handleDeleteClick}>
            Delete
          </button>
        </div>
      </div>
      {question ? (
        <>
          {/* Display question details */}
          <div>
            <h4>Statement</h4>
            <div dangerouslySetInnerHTML={{ __html: question.statement }} />
            <h4>Options</h4>

            <ol class="list-group list-group-numbered">
              {question.options.map((option, index) => (
                <li
                  class="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">
                      {" "}
                      <div dangerouslySetInnerHTML={{ __html: option }} />
                    </div>
                  </div>
                  {question.correct === index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      fill="green"
                      class="bi bi-check-square-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                    </svg>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ol>

            {question.explanation ? (
              <>
                <h4 className="mt-3">Explanation</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              </>
            ) : (
              <></>
            )}

            {question.year ? (
              <>
                <h4 className="mt-3">Year</h4>
                <p>{question.year}</p>
              </>
            ) : (
              <></>
            )}

            <h4>Positive Marks</h4>
            <p>{question.pmarks}</p>
            <h4>Negative Marks</h4>
            <p>{question.nmarks}</p>
            <h4>Created At</h4>
            <p>{new Date(question.createdAt).toLocaleString()}</p>
            <h4>Updated At</h4>
            <p>{new Date(question.updatedAt).toLocaleString()}</p>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleConfirmDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QuestionDetail;
