import React from "react";

const SideBar = () => {
  const isLoggedIn = !!localStorage.getItem("token");

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("token");
    console.log("Logged out");

    // You can also perform additional actions after logout if needed
    window.location.reload();
  };

  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark"
      style={{ width: "100%", height: "100vh" }}
    >
      <a
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <span className="fs-4">SaralUPSC CMS</span>
      </a>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <a href="/" className="nav-link text-white" aria-current="page">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#home"></use>
            </svg>
            Home
          </a>
        </li>
        <hr />

        <li>
          <a href="/topicTests" className="nav-link text-white">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#speedometer2"></use>
            </svg>
            Topic Tests
          </a>
        </li>

        <li>
          <a href="/topicquestions" className="nav-link text-white">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#speedometer2"></use>
            </svg>
            Topic Questions
          </a>
        </li>

        <hr />

        <li>
          <a href="/blogs" className="nav-link text-white">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#speedometer2"></use>
            </svg>
            Blogs
          </a>
        </li>

        <hr />

        <li>
          <a href="/tests" className="nav-link text-white">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#table"></use>
            </svg>
            Tests
          </a>
        </li>

        <li>
          <a href="/questions" className="nav-link text-white">
            <svg className="bi pe-none me-2" width="16" height="16">
              <use xlinkHref="#table"></use>
            </svg>
            Questions
          </a>
        </li>
      </ul>
      <hr />
      {isLoggedIn ? (
        <div className="dropdown">
          <a
            href="#"
            className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://github.com/mdo.png"
              alt=""
              width="32"
              height="32"
              className="rounded-circle me-2"
            />
            <strong>mdo</strong>
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
            <li>
              <a className="dropdown-item" href="#" onClick={handleLogout}>
                Sign out
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <div className="mt-auto">
          <div className="dropdown">
            <a
              href="#"
              className="text-white text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg className="bi pe-none me-2" width="16" height="16">
                <use xlinkHref="#person-circle"></use>
              </svg>
              Account
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li>
                <a className="dropdown-item" href="#">
                  Register
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBar;
