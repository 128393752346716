import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "./../../config/axiosConfig";
import { Modal } from "react-bootstrap";

function TestDetail() {
  const { testId } = useParams();
  const [test, setTest] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchTest() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}topicTests/${testId}`
        );
        setTest(response.data);
      } catch (error) {
        console.error("Error fetching test:", error);
      }
    }

    fetchTest();
  }, [testId]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}tests/${testId}`);
      // Redirect or perform other actions after deletion
      navigate("/tests");
    } catch (error) {
      console.error("Error deleting test:", error);
    }
  };

  console.log(test);
  return (
    <div className="container mt-4">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-secondary me-4"
          >
            Back
          </button>
          <h2>Topic Detail - {test ? test.name : <></>}</h2>
        </div>
        <div>
          <Link
            to={`/edit-topic-test/${testId}`}
            className="btn btn-primary ms-2"
          >
            Edit
          </Link>
          <button className="btn btn-danger ms-2" onClick={handleDeleteClick}>
            Delete
          </button>
        </div>
      </div>
      {test ? (
        <>
          {/* Display test details */}
          <div>
            <h4>Total Questions</h4>
            <p>{test.questions.length}</p>
            <h4>Questions</h4>
            <ol class="list-group list-group-numbered">
              {test.questions.map((question) => {
                return (
                  <a
                    href={"/topicquestions/" + question._id}
                    class="list-group-item d-flex justify-content-between align-items-start"
                  >
                    <div class="ms-2 me-auto">
                      <div class="fw-bold">
                        {question.statement.slice(0, 50)}
                      </div>
                    </div>
                    {question.year ? (
                      <span class="badge bg-primary rounded-pill">
                        {question.year}
                      </span>
                    ) : (
                      <></>
                    )}
                  </a>
                );
              })}
            </ol>
            <h4 className="mt-3">Subject</h4>
            <p>{test.subject}</p>
            <h4 className="mt-3">Created At</h4>
            <p>{new Date(test.createdAt).toLocaleString()}</p>
            <h4>Updated At</h4>
            <p>{new Date(test.updatedAt).toLocaleString()}</p>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this test?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleConfirmDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TestDetail;
