import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "katex/dist/katex.min.css";
import "katex/dist/contrib/auto-render.min";
import modules from "../../config/modules";
import renderMathInElement from "katex/dist/contrib/auto-render";
import axios from "./../../config/axiosConfig";

function EditQuestion() {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const [editorHtml, setEditorHtml] = useState("");
  const [explanationHtml, setExplanationHtml] = useState("");
  const [options, setOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState("");
  const [positiveMarks, setPositiveMarks] = useState(0);
  const [negativeMarks, setNegativeMarks] = useState(0);
  const [year, setYear] = useState("");

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}questions/${questionId}`
        );
        const questionData = response.data;
        setEditorHtml(questionData.statement);
        setExplanationHtml(questionData.explanation);
        setOptions(questionData.options);

        setCorrectOption(questionData.correct);
        setPositiveMarks(questionData.pmarks);
        setNegativeMarks(questionData.nmarks);
        setYear(questionData.year);

        renderMathInElement(document.getElementById("editor"), {
          delimiters: [
            { left: "$$", right: "$$", display: true },
            { left: "\\[", right: "\\]", display: true },
            { left: "$", right: "$", display: false },
            { left: "\\(", right: "\\)", display: false },
          ],
        });
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    }

    fetchQuestion();
  }, [questionId]);

  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };

  const handleExplanationChange = (html) => {
    setExplanationHtml(html);
  };

  const handleAddOption = () => {
    setOptions([...options, { value: "", correct: false }]);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value; // Update the option at the specified index
    setOptions(updatedOptions);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOption(index);
  };

  const handleUpdateQuestion = async () => {
    // Perform validations
    if (
      !editorHtml ||
      options.length < 4 ||
      options.some((option) => option.trim() === "") ||
      correctOption === ""
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    // Prepare the updated question data
    const updatedQuestionData = {
      statement: editorHtml,
      explanation: explanationHtml,
      options: options,
      correct: correctOption,
      pmarks: parseInt(positiveMarks),
      nmarks: parseInt(negativeMarks),
      year: year,
    };

    try {
      // Send a PUT request to update the question
      await axios.patch(
        `${process.env.REACT_APP_API_URL}questions/${questionId}`,
        updatedQuestionData
      );
      console.log("Question updated");

      // Redirect to the updated question detail page
      navigate(`/questions/${questionId}`);
    } catch (error) {
      console.error("Error updating question:", error);
      alert("An error occurred while updating the question.");
    }
  };

  return (
    <div className="container mt-4">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-secondary me-3"
          >
            Back
          </button>
          <h2>Edit Question</h2>
        </div>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleUpdateQuestion}
        >
          Update Question
        </button>
      </div>

      <form>
        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="statement" className="form-label">
              Statement:
            </label>
          </div>
          <div className="col-md-6">
            <ReactQuill
              id="editor"
              value={editorHtml}
              onChange={handleEditorChange}
              modules={modules} // Use the modules object here
            />
          </div>
          <div className="col-md-5">
            <h5>Question Preview</h5>
            <div dangerouslySetInnerHTML={{ __html: editorHtml }} />
          </div>
        </div>

        <div>
          <h3 className="mb-3">Options</h3>
          {options.map((option, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-md-1">
                <label htmlFor={`option${index}`} className="form-label">
                  Option {index + 1}:
                </label>
              </div>
              <div className="col-md-6">
                <ReactQuill
                  id={`option${index}`}
                  value={option} // Use the option string here
                  onChange={(html) => handleOptionChange(index, html)}
                  modules={modules} // Use the modules object here
                />
              </div>
              <div className="col-md-5">
                <h5>Option Preview</h5>
                <div dangerouslySetInnerHTML={{ __html: option }} />
              </div>
            </div>
          ))}
        </div>

        <div className="row mb-3">
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <button
              type="button"
              className="btn btn-primary mb-3 w-100"
              onClick={handleAddOption}
            >
              Add More Option
            </button>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="correctOption" className="form-label">
              Correct Option:
            </label>
          </div>
          <div className="col-md-4">
            <select
              id="correctOption"
              value={correctOption}
              onChange={(e) => handleCorrectOptionChange(e.target.value)}
              className="form-select"
            >
              <option value="">Select Correct Option</option>
              {options.map((_, index) => (
                <option key={index} value={index}>
                  Option {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1">
            <label htmlFor="positiveMarks" className="form-label">
              Positive Marks:
            </label>
          </div>
          <div className="col-md-2">
            <input
              type="number"
              id="positiveMarks"
              value={positiveMarks}
              onChange={(e) => setPositiveMarks(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-1">
            <label htmlFor="negativeMarks" className="form-label">
              Negative Marks:
            </label>
          </div>
          <div className="col-md-2">
            <input
              type="number"
              id="negativeMarks"
              value={negativeMarks}
              onChange={(e) => setNegativeMarks(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="explanation" className="form-label">
              Explanation:
            </label>
          </div>
          <div className="col-md-6">
            <ReactQuill
              value={explanationHtml}
              onChange={handleExplanationChange}
              modules={modules} // Use the modules object here
            />
          </div>
          <div className="col-md-5">
            <h5>Explanation Preview</h5>
            <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="year" className="form-label">
              Year:
            </label>
          </div>
          <div className="col-md-2">
            <input
              type="text"
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditQuestion;
