import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./LandingPage";

import QuestionDetail from "./components/Questions/QuestionDetail";
import AddQuestion from "./components/Questions/AddQuestion";
import QuestionList from "./components/Questions/QuestionList";
import EditQuestion from "./components/Questions/EditQuestion";

import TopicQuestionDetail from "./components/TopicQuestions/TopicQuestionDetail";
import AddTopicQuestion from "./components/TopicQuestions/AddTopicQuestion";
import TopicQuestionList from "./components/TopicQuestions/TopicQuestionList";
import EditTopicQuestion from "./components/TopicQuestions/EditTopicQuestion";

import TopicTestDetail from "./components/TopicTest/TopicTestDetail";
import AddTopicTest from "./components/TopicTest/AddTopicTest";
import TopicTestList from "./components/TopicTest/TopicTestList";
import EditTopicTest from "./components/TopicTest/EditTopicTest";

import TestList from "./components/Tests/TestList";
import AddTest from "./components/Tests/AddTest";
import TestDetail from "./components/Tests/TestDetail";
import EditTest from "./components/Tests/EditTest";

import AddBlog from "./components/Blogs/AddBlog";
import BlogList from "./components/Blogs/BlogList";
import BlogDetail from "./components/Blogs/BlogDetail";
import EditBlog from "./components/Blogs/EditBlog";

import Login from "./components/Login";
import Register from "./components/Register";

function App() {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Login />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/add-question" element={<AddQuestion />} />
        <Route path="/questions" element={<QuestionList />} />
        <Route path="/questions/:questionId" element={<QuestionDetail />} />
        <Route path="/edit-question/:questionId" element={<EditQuestion />} />

        <Route path="/add-topic-question" element={<AddTopicQuestion />} />
        <Route path="/topicquestions" element={<TopicQuestionList />} />
        <Route
          path="/topicquestions/:questionId"
          element={<TopicQuestionDetail />}
        />
        <Route
          path="/edit-topic-question/:questionId"
          element={<EditTopicQuestion />}
        />

        <Route path="/add-blog" element={<AddBlog />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:blogId" element={<BlogDetail />} />
        <Route path="/edit-blog/:blogId" element={<EditBlog />} />

        <Route path="/add-test" element={<AddTest />} />
        <Route path="/tests" element={<TestList />} />
        <Route path="/tests/:testId" element={<TestDetail />} />
        <Route path="/edit-test/:testId" element={<EditTest />} />

        <Route path="/add-topic-test" element={<AddTopicTest />} />
        <Route path="/topictests" element={<TopicTestList />} />
        <Route path="/topictests/:testId" element={<TopicTestDetail />} />
        <Route path="/edit-topic-test/:testId" element={<EditTopicTest />} />

        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
