import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    fetchAnalyticsData();
  }, []);

  const fetchAnalyticsData = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}user/analytics`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setAnalyticsData(data);
      } else {
        console.error("Failed to fetch analytics data");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <div className="container">
      <h1 className="mt-5">CMS Analytics</h1>

      {analyticsData ? (
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">Questions Uploaded Last Week</div>
              <div className="card-body">
                <h5 className="card-title">
                  {analyticsData.questionsCreatedLastWeek}
                </h5>
                <p className="card-text">
                  You uploaded {analyticsData.questionsCreatedLastWeek}{" "}
                  questions in the last week.
                </p>
                <Link to="/add-question" className="btn btn-primary">
                  Create Question
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-header">Tests Created Last Week</div>
              <div className="card-body">
                <h5 className="card-title">
                  {analyticsData.testsCreatedLastWeek}
                </h5>
                <p className="card-text">
                  You created {analyticsData.testsCreatedLastWeek} tests in the
                  last week.
                </p>
                <Link to="/add-test" className="btn btn-primary">
                  Create Test
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-header">Blogs Created Last Week</div>
              <div className="card-body">
                <h5 className="card-title">
                  {analyticsData.blogsCreatedLastWeek}
                </h5>
                <p className="card-text">
                  You created {analyticsData.blogsCreatedLastWeek} blogs in the
                  last week.
                </p>
                <Link to="/add-blog" className="btn btn-primary">
                  Create Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading analytics data...</div>
      )}

      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">Topic Questions Created Last Week</div>
            <div className="card-body">
              <h5 className="card-title">
                {analyticsData && analyticsData.topicQuestionCreatedLastWeek}
              </h5>
              <p className="card-text">
                You created{" "}
                {analyticsData && analyticsData.topicQuestionCreatedLastWeek}{" "}
                topic questions in the last week.
              </p>
              <Link to="/add-topic-question" className="btn btn-primary">
                Create Topic Question
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-header">Topic Tests Created Last Week</div>
            <div className="card-body">
              <h5 className="card-title">
                {analyticsData && analyticsData.topicTestCreatedLastWeek}
              </h5>
              <p className="card-text">
                You created{" "}
                {analyticsData && analyticsData.topicTestCreatedLastWeek} topic
                tests in the last week.
              </p>
              <Link to="/add-topic-test" className="btn btn-primary">
                Create Topic Test
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
