import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "katex/dist/katex.min.css";
import "katex/dist/contrib/auto-render.min";
import modules from "../../config/modules";
import renderMathInElement from "katex/dist/contrib/auto-render";
import axios from "./../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

function AddQuestion() {
  const [editorHtml, setEditorHtml] = useState("");
  const [explanationHtml, setExplanationHtml] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState("");
  const [positiveMarks, setPositiveMarks] = useState(2);
  const [negativeMarks, setNegativeMarks] = useState(0.66);
  const [year, setYear] = useState(""); // New year state
  const navigate = useNavigate();

  useEffect(() => {
    renderMathInElement(document.getElementById("editor"), {
      delimiters: [
        { left: "$$", right: "$$", display: true },
        { left: "\\[", right: "\\]", display: true },
        { left: "$", right: "$", display: false },
        { left: "\\(", right: "\\)", display: false },
      ],
    });
  }, [editorHtml]);

  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };

  const handleExplanationChange = (html) => {
    setExplanationHtml(html);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleOptionDelete = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOptionIndex(index);
  };

  const handleAddQuestion = async () => {
    // Perform validations
    if (
      !editorHtml ||
      options.length < 4 ||
      options.some((option) => option.trim() === "") ||
      correctOptionIndex === ""
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    // Prepare the question data
    const questionData = {
      statement: editorHtml,
      explanation: explanationHtml,
      options: options,
      correct: parseInt(correctOptionIndex),
      pmarks: parseFloat(positiveMarks),
      nmarks: parseFloat(negativeMarks),
      year: parseInt(year), // Set the year value
    };

    try {
      // Send a POST request to add the question
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "questions",
        questionData
      );
      console.log("Question added:", response.data);

      // Reset the state values after adding the question
      setEditorHtml("");
      setExplanationHtml("");
      setOptions(["", "", "", ""]);
      setCorrectOptionIndex("");
      setPositiveMarks(2);
      setNegativeMarks(0.66);
      setYear("");

      alert("Question added successfully!");
    } catch (error) {
      console.error("Error adding question:", error);
      alert("An error occurred while adding the question.");
    }
  };

  return (
    <div className="container mt-4">
      <div className="mb-3 d-flex align-items-center">
        <button onClick={() => navigate(-1)} className="btn btn-secondary me-3">
          Back
        </button>
        <h2>Add Question</h2>
      </div>

      <form>
        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="statement" className="form-label">
              Statement:
            </label>
          </div>
          <div className="col-md-6">
            <ReactQuill
              id="editor"
              value={editorHtml}
              onChange={handleEditorChange}
              modules={modules} // Use the modules object here
            />
          </div>
          <div className="col-md-5">
            <h5>Question Preview</h5>
            <div dangerouslySetInnerHTML={{ __html: editorHtml }} />
          </div>
        </div>

        <div>
          <h3 className="mb-3">Options</h3>
          {options.map((option, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-md-1">
                <label htmlFor={`option${index}`} className="form-label">
                  Option {index + 1}:
                </label>
              </div>
              <div className="col-md-6">
                <ReactQuill
                  id={`option${index}`}
                  value={option}
                  onChange={(html) => handleOptionChange(index, html)}
                  modules={modules} // Use the modules object here
                />
                <button
                  type="button"
                  className="btn btn-danger mt-2 w-100"
                  onClick={() => handleOptionDelete(index)}
                >
                  Delete Above Option
                </button>
              </div>
              <div className="col-md-5">
                <h5>Option Preview</h5>
                <div dangerouslySetInnerHTML={{ __html: option }} />
              </div>
            </div>
          ))}
        </div>

        <div className="row mb-3">
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <button
              type="button"
              className="btn btn-primary mb-3 w-100"
              onClick={handleAddOption}
            >
              Add More Option
            </button>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="correctOption" className="form-label">
              Correct Option:
            </label>
          </div>
          <div className="col-md-4">
            <select
              id="correctOption"
              value={correctOptionIndex}
              onChange={(e) => handleCorrectOptionChange(e.target.value)}
              className="form-select"
            >
              <option value="">Select Correct Option</option>
              {options.map((_, index) => (
                <option key={index} value={index}>
                  Option {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1">
            <label htmlFor="positiveMarks" className="form-label">
              Positive Marks:
            </label>
          </div>
          <div className="col-md-2">
            <input
              type="number"
              id="positiveMarks"
              value={positiveMarks}
              onChange={(e) => setPositiveMarks(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-1">
            <label htmlFor="negativeMarks" className="form-label">
              Negative Marks:
            </label>
          </div>
          <div className="col-md-2">
            <input
              type="number"
              id="negativeMarks"
              value={negativeMarks}
              onChange={(e) => setNegativeMarks(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="year" className="form-label">
              Year:
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="number"
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="explanation" className="form-label">
              Explanation:
            </label>
          </div>
          <div className="col-md-6">
            <ReactQuill
              value={explanationHtml}
              onChange={handleExplanationChange}
              modules={modules} // Use the modules object here
            />
          </div>
          <div className="col-md-5">
            <h5>Explanation Preview</h5>
            <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-success w-100"
              onClick={handleAddQuestion}
            >
              Add Question
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddQuestion;
