import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "katex/dist/katex.min.css";
import "katex/dist/contrib/auto-render.min";
import modules from "../../config/modules";
import renderMathInElement from "katex/dist/contrib/auto-render";
import axios from "./../../config/axiosConfig";
import subjectOptions from "../../config/subjectOptions";

function EditBlog() {
  const { blogId } = useParams();
  const navigate = useNavigate();
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [poster, setPoster] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // Fetch questions to populate the list
    fetchQuestions(currentPage, searchText);
  }, [currentPage, searchText]);

  const fetchQuestions = async (page, query) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}questions?page=${page}&statement=${query}`
      );
      setAllQuestions(response.data.questions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleQuestionSelect = (event) => {
    const selectedQuestionId = event.target.value;
    const selectedQuestion = allQuestions.find(
      (q) => q._id === selectedQuestionId
    );

    if (selectedQuestion && !selectedQuestions.includes(selectedQuestion)) {
      setSelectedQuestions([...selectedQuestions, selectedQuestion]);
    }
  };

  const handleQuestionRemove = (questionId) => {
    const updatedSelectedQuestions = selectedQuestions.filter(
      (question) => question._id !== questionId
    );
    setSelectedQuestions(updatedSelectedQuestions);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchQuestions(1, searchText);
  };

  useEffect(() => {
    async function fetchBlog() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}blogs/${blogId}`
        );
        const blogData = response.data;
        setDesc(blogData.desc);
        setTitle(blogData.title);
        setSubject(blogData.subject);
        setMetaDesc(blogData.meta_desc);
        setMetaKeywords(blogData.meta_keywords);
        setPoster(blogData.poster);

        if (blogData.questions) {
          setSelectedQuestions(blogData.questions);
        }

        renderMathInElement(document.getElementById("editor"), {
          delimiters: [
            { left: "$$", right: "$$", display: true },
            { left: "\\[", right: "\\]", display: true },
            { left: "$", right: "$", display: false },
            { left: "\\(", right: "\\)", display: false },
          ],
        });
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    }

    fetchBlog();
  }, [blogId]);

  const handleUpdateBlog = async () => {
    // Perform validations
    if (
      !desc ||
      title === "" ||
      metaDesc === "" ||
      metaKeywords === "" ||
      poster === "" ||
      subject === ""
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    // Prepare the updated blog data
    const updatedBlogData = {
      title,
      desc: desc,
      meta_desc: metaDesc,
      meta_keywords: metaKeywords,
      poster,
      subject,
    };

    if (selectedQuestions.length > 0) {
      const selectedQuestionIds = selectedQuestions.map(
        (question) => question._id
      );

      updatedBlogData.questions = selectedQuestionIds;
    }

    try {
      // Send a PATCH request to update the blog
      await axios.patch(
        `${process.env.REACT_APP_API_URL}blogs/${blogId}`,
        updatedBlogData
      );
      console.log("Blog updated");

      // Redirect to the updated blog detail page
      navigate(`/blogs/${blogId}`);
    } catch (error) {
      console.error("Error updating blog:", error);
      alert("An error occurred while updating the blog.");
    }
  };

  return (
    <div className="container mt-4">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className=" d-flex align-items-center">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-secondary me-3"
          >
            Back
          </button>
          <h2>Add Blog</h2>
        </div>

        <button
          type="button"
          className="btn btn-success "
          onClick={handleUpdateBlog}
        >
          Update Blog
        </button>
      </div>

      <form>
        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="title" className="form-label">
              Title:
            </label>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-1">
            <label htmlFor="subject" className="form-label">
              Subject:
            </label>
          </div>
          <div className="col-md-5">
            <select
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="form-select"
            >
              <option value="">Select a subject</option>
              {subjectOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="metaDesc" className="form-label">
              Meta Description:
            </label>
          </div>
          <div className="col-md-5">
            <textarea
              id="metaDesc"
              value={metaDesc}
              onChange={(e) => setMetaDesc(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-1">
            <label htmlFor="metaKeywords" className="form-label">
              Meta Keywords:
            </label>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              id="metaKeywords"
              value={metaKeywords}
              onChange={(e) => setMetaKeywords(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-1">
            <label htmlFor="poster" className="form-label">
              Poster URL:
            </label>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              id="poster"
              value={poster}
              onChange={(e) => setPoster(e.target.value)}
              className="form-control"
            />
            <img src={poster} className="img-fluid" width="100%" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="desc" className="form-label">
              Description:
            </label>
            <ReactQuill
              value={desc}
              onChange={setDesc}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="preview" className="form-label">
              Preview:
            </label>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="aquestions" className="form-label">
              Avialable Questions:
            </label>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Questions"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary mt-2"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <select
              multiple
              className="form-control"
              id="questions"
              onChange={handleQuestionSelect}
              size="10"
            >
              {allQuestions
                .filter(
                  (question) =>
                    !selectedQuestions.some(
                      (selected) => selected._id === question._id
                    )
                )
                .map((question) => (
                  <option key={question._id} value={question._id}>
                    {question.statement}
                  </option>
                ))}
            </select>

            {/* Pagination */}
            <nav aria-label="Questions Pagination">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-md-6">
            <label htmlFor="Selected Questions" className="form-label">
              Selected Questions
            </label>
            <ol className="list-group list-group-numbered">
              {selectedQuestions.map((question) => (
                <li
                  key={question._id}
                  className="list-group-item d-flex justify-content-between align-items-start"
                >
                  {question.statement}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleQuestionRemove(question._id)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditBlog;
