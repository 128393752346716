import React, { useState, useEffect } from "react";
import axios from "./../../config/axiosConfig";
import { Link } from "react-router-dom";

function QuestionList() {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [subjectFilter, setSubjectFilter] = useState("");
  const [hasQuestionsFilter, setHasQuestionsFilter] = useState(false);

  const fetchBlogs = async (page) => {
    try {
      let apiUrl = `${process.env.REACT_APP_API_URL}blogs?page=${page}&title=${searchText}&subject=${subjectFilter}`;

      if (hasQuestionsFilter) {
        apiUrl += "&hasQuestions=true";
      }

      const response = await axios.get(apiUrl);
      const { blogs, totalPages } = response.data;
      setBlogs(blogs);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage, hasQuestionsFilter]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page when searching
    fetchBlogs(1); // Fetch blogs with updated searchText
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setHasQuestionsFilter(false);
    setSubjectFilter("");
    setSearchText("");
    fetchBlogs(1); // Fetch blogs with cleared filters
  };

  return (
    <>
      <div className="container mt-4">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <div>
            <h2>Blogs</h2>
            <p>Around {totalPages * 10} Blogs found</p>
          </div>
          <div>
            <div className="input-group">
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={handleToggleFilters}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
              </button>

              <Link to={`/add-blog`} className="btn btn-success ms-2">
                Add Blog
              </Link>
            </div>
          </div>
        </div>

        <div className="collapse mb-4" id="collapseExample">
          <div className="card card-body">
            <div className="row mt-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by statement"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>

              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={subjectFilter}
                  onChange={(e) => setSubjectFilter(e.target.value)}
                />
              </div>

              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="hasQuestionsFilter"
                    checked={hasQuestionsFilter}
                    onChange={() => setHasQuestionsFilter(!hasQuestionsFilter)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="hasQuestionsFilter"
                  >
                    Has Questions
                  </label>
                </div>
              </div>

              <div className="col">
                <button className="btn btn-primary me-2" onClick={handleSearch}>
                  Search
                </button>

                <button className="btn btn-danger" onClick={handleClearFilters}>
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Date Added</th>
              <th>Title</th>
              <th>Subject</th>
              <th>Questions</th>
              <th>Poster</th>
              <th>meta_desc</th>
              <th>meta_keywords</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog) => (
              <tr key={blog._id}>
                <td>{blog.createdAt}</td>
                <td>
                  <a href={`/blogs/${blog._id}`}>{blog.title.slice(0, 150)}</a>
                </td>
                <td>{blog.subject}</td>
                <td>
                  {blog.questions.length > 0 ? blog.questions.length : "-"}
                </td>
                <td>
                  <img src={blog.poster} height="70px"></img>
                </td>
                <td>{blog.meta_desc}</td>
                <td>{blog.meta_keywords}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav aria-label="...">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => {
              if (index < 3 || index >= totalPages - 3) {
                return (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                );
              } else if (index === 3) {
                return (
                  <li key={index} className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                );
              }
              return null; // Skip rendering for middle page numbers
            })}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default QuestionList;
